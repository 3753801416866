.container {
    width:110px;
    height:35px;
    border-radius:2px;
    font-size:16px;
    font-weight:bold;
    background-color:#0079e3;
    cursor:pointer;
    line-height: 35px;
    text-align: center;
    color:white;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
}

.loaderContainer {

    width:110px; 
    height:35px; 
    display:flex;
    align-items:center;
    justify-content:center;
}