.container {
    width:100%;
    height:370px;
    margin-top:20px;
    margin-top:100px;
}

.title {
 font-size:14px;
 margin-left:30px;
 font-weight:bold;
 text-align:left;
}

.textBox {
width:90%;
height:200px;
border-style:solid;
border-width:1px;
border-color:black;
margin-left:30px;
padding:10px;
resize: none;
}

.rectButton {
    width:110px;
    height:35px;
    border-radius:2px;
    font-size:16px;
    font-weight:bold;
    margin-left:15px;
    background-color:#0079e3;
    cursor:pointer;
    line-height: 35px;
    text-align: center;
    color:white;
    margin: 0 auto;
    margin-top:30px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
    }
    
.rectButton:hover{
    background-color:#85c6ff;  
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 5px;
 }

