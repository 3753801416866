.container {
    width:100%;
    height:60px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px;
    background-color:white;
    position:fixed;
    z-index:5;
    display:flex;
    align-items: center;
}


.logoCenter {
    width:50px;
    height:50px;
    margin:0 auto;
    cursor: pointer;
}

.rightBox {
    width:150px;
    height:60px;
    position: absolute;
    top:0;
    right:15px;
    display:flex;
    align-items: center;

}

.navButton {
    font-size:14px;
    font-weight:800px;
    margin-left:15px;
    color:#0079e3;
    cursor:pointer;
    }
    
.agreeButton:hover{
    color:#85c6ff;  
 }